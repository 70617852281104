<template>
  <b-card>
    <FormView
      :data="$store.getters['voucher/detailData']"
      :fields="fields"
      @load="loadData"
    />
  </b-card>
</template>

<script>
import FormView from '@/layouts/components/FormView.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormView,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'id',
          type: 'id',
          label: 'ID',
        },
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'amount',
          label: 'Amount',
          type: 'gold',
        },
        {
          key: 'numOfVoucher',
          label: 'Total Voucher',
        },
        {
          key: 'limitUsage',
          label: 'Limit Usage',
        },
        {
          key: 'startDate',
          label: 'Start Date',
          type: 'datetime',
        },
        {
          key: 'endDate',
          label: 'End Date',
          type: 'datetime',
        },
        {
          key: 'createdAt',
          label: 'Created at',
          type: 'datetime',
        },
      ],
    }
  },
  methods: {
    loadData(id) {
      return this.$store.dispatch('voucher/getDetail', id)
    },
  },
}
</script>

  <style scoped>

  </style>
